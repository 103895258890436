<template>
  <div style="overflow: scroll">
    <Top></Top>
    <div class="modole" style="justify-content: space-between">
      <div style="display: flex">
        <div class="stick"></div>
        <div class="title">{{ lang.v.starcard }}</div>
      </div>
    </div>
    <div class="xingka">
      {{ lang.v.describe }}
    </div>
    <div style="background-color: #f6f6f8">
      <div class="main">
        <div class="become_login">
          <div class="type_login" v-for="role in types_login" :key="role.type">
            <div class="type_top"></div>
            <div class="card_login">
              <div class="card_logo">
                <img
                  :src="
                    role.type == 1
                      ? card_logo.imageSrc
                      : card_logo.otherImageSrc
                  "
                  alt=""
                />
              </div>
              <div
                class="card_name"
                :style="{ color: role.type == 1 ? '#5EB15C' : '#ED931E' }"
              >
                {{ role.name }}
              </div>
            </div>
            <div
              class="multiple"
              style="display: none"
              :innerText="role.type == 1 ? lang.v.banQuan1 : lang.v.banQuan2"
            ></div>
            <div class="Unlock" @click="gopay(role)">
              <div>
                <img
                  style="
                    width: 100%;
                    height: 0.3rem;
                    line-height: 0.3rem;
                    border-radius: 0.06rem;
                  "
                  :src="role.type == 1 ? imgs.imgs1 : imgs.imgs2"
                  alt=""
                />
              </div>
              <div
                style="margin-top: -0.3rem"
                :innerHTML="
                  role.type == 1
                    ? lang.v.unit + text1 + unlock
                    : lang.v.unit + text2 + unlock
                "
              ></div>
            </div>
            <div class="task" v-for="task in role.equityList" :key="task">
              <div class="completion">
                <!-- <div class="complete"><img :src="role.type == 1 ? bingo.imageSrc : bingo.otherImageSrc" alt="">
                            </div> -->
                <div
                  class="points"
                  :style="
                    role.type == 1
                      ? 'background-color: #5EB15C'
                      : 'background-color: #ED931E'
                  "
                ></div>
                <div class="task_describe">{{ task }}</div>
              </div>
              <!-- <p v-if="task.p == '' ? false : true">{{ task.p }}</p>
                <a style="font-size: 0.14rem;margin-left: 0.26rem;" class="view" v-if="task.a == '' ? false : true"
                    href="">{{ task.a }}</a> -->
            </div>
          </div>
        </div>
      </div>
      <div class="new_box">
        <div class="new_name">{{ lang.v.starReward }}</div>
        <div class="new_contant">
          <div class="new_text">{{ lang.v.text1 }}</div>
          <div class="new_text">{{ lang.v.text2 }}</div>
          <div class="new_text">{{ lang.v.text3 }}</div>
        </div>
      </div>
      <div class="new_box">
        <div class="new_name">{{ lang.v.starClu }}</div>
        <div class="new_contant">
          <div class="new_text">{{ lang.v.text4 }}</div>
        </div>
      </div>
      <div class="modole">
        <div class="stick"></div>
        <div class="title">{{ lang.v.interests }}</div>
      </div>
      <div class="characteristic">
        <div class="box" v-for="role in privileges" :key="role.id">
          <div class="characteristic_logo">
            <img :src="role.imgs" alt="" />
          </div>
          <div class="characteristic_name">{{ role.name }}</div>
          <div class="characteristic_describe">{{ role.describe }}</div>
        </div>
      </div>
    </div>
    <van-dialog
      style="z-index: 9999"
      v-model:show="show"
      :title="log_fir"
      @confirm="ret_login"
      show-cancel-button
    ></van-dialog>
    <Tabbar />
  </div>
</template>
<script setup>
import { onMounted, reactive, ref } from 'vue';
import router from '@/router';
import { card_info, card_pay, personal } from '@/api/fetcher';
import { lang } from '@/lang';
import Top from '@/components/Top/top.vue';
import Tabbar from '@/components/Tabbar/tabbar.vue';
const unlock = lang.v.unlock;
const text1 = ref('???');
const log_fir = lang.v.log_fir;
const text2 = ref('???');
const goldId = ref('1');
const imgs = ref({
  imgs1: require('@/assets/img/ordinary_pay.png'),
  imgs2: require('@/assets/img/senior_pay.png'),
});
const name7 = lang.v.name7;
const name8 = lang.v.name8;
const name9 = lang.v.name9;
const name10 = lang.v.name10;
const describe5 = lang.v.describe5;
const describe6 = lang.v.describe6;
const describe7 = lang.v.describe7;
const describe8 = lang.v.describe8;
const privileges = reactive([
  {
    id: 1,
    imgs: require('@/assets/img/daren_1.png'),
    name: name7,
    describe: describe5,
  },
  {
    id: 2,
    imgs: require('@/assets/img/daren_2.png'),
    name: name8,
    describe: describe6,
  },
  {
    id: 3,
    imgs: require('@/assets/img/daren_3.png'),
    name: name9,
    describe: describe7,
  },
  {
    id: 4,
    imgs: require('@/assets/img/daren_4.png'),
    name: name10,
    describe: describe8,
  },
]);

const types_login = ref([]);

// 达人卡的logo
const card_logo = reactive({
  imageSrc: require('@/assets/img/ordinary.png'),
  otherImageSrc: require('@/assets/img/senior.png'),
});

const ret_login = () => {
  router.push('/login');
};
// 开通达人卡
const gopay = e => {
  sessionStorage.setItem('intelligent_id', JSON.stringify(e.type));
  router.push('/open_intelligent');
};

const show = ref(false);
const userinfo = ref({});

onMounted(async () => {
  try {
    const userInfoString = localStorage.getItem('userInfo');
    if (userInfoString && userInfoString !== 'undefined') {
      userinfo.value = JSON.parse(userInfoString);
    }
  } catch (e) {
    console.error('Error parsing userInfo from localStorage:', e);
  }
  sessionStorage.setItem('intelligent_id', JSON.stringify(goldId.value));
  goldId.value = JSON.parse(sessionStorage.getItem('intelligent_id'));
  if (localStorage.getItem('userInfo')) {
    await card_info().then(async res => {
      types_login.value = res;
    });
    personal().then(res => {
      window.localStorage.setItem('userInfo', JSON.stringify(res));
      try {
        const userInfoString = localStorage.getItem('userInfo');
        if (userInfoString && userInfoString !== 'undefined') {
          userinfo.value = JSON.parse(userInfoString);
        }
      } catch (e) {
        console.error('Error parsing userInfo from localStorage:', e);
      }
      if (
        userinfo.value.talentCardType == 0 ||
        userinfo.value.talentCardType == null
      ) {
        return;
      } else {
        router.push('/become_intelligent');
        console.log(userinfo.value.talentCardType);
      }
    });
  } else {
    return;
  }
  await card_pay().then(res => {
    console.log(res, 444);

    text1.value = [...res][0].list[0].price;
    text2.value = [...res][1].list[0].price;
  });
});
</script>
<style lang="less" scoped>
.xingka {
  width: 90.4%;
  font-size: 0.14rem;
  font-family: PingFang SC, PingFang SC;
  font-weight: 400;
  color: #858c9f;
  line-height: 0.2rem;
  text-align: justify;
  margin: auto;
  margin-top: 0.12rem;
}

.nav {
  width: 100%;
  height: 0;
  padding-bottom: 42.67%;
  background: url('~@/assets/img/banner_other.png') no-repeat;
  background-position: center;
  background-size: 100% auto;
}

.main {
  width: 92.5%;
  margin: auto;
  margin-top: 0.12rem;
  padding-top: 0.14rem;
  padding-bottom: 0.14rem;

  .tab {
    width: 91.93%;
    height: 0.44rem;
    background: rgba(133, 140, 159, 0.06);
    margin: auto;
    border-radius: 0.06rem;
    display: flex;

    .ordinary {
      width: 46.71%;
      height: 0.3rem;
      text-align: center;
      line-height: 0.3rem;
      font-size: 0.13rem;
      font-family: PingFang SC, PingFang SC;
      font-weight: 400;
      color: #858c9f;
      margin-left: 2.19%;
      margin-top: 0.07rem;
    }

    .act-ordinary {
      width: 46.71%;
      height: 0.3rem;
      text-align: center;
      line-height: 0.3rem;
      font-size: 0.13rem;
      font-family: PingFang SC, PingFang SC;
      font-weight: 500;
      color: #444444;
      margin-left: 2.19%;
      background: #ffffff;
      box-shadow: 0rem 0rem 0.08rem 0rem rgba(133, 140, 159, 0.15);
      border-radius: 0.04rem;
      margin-top: 0.07rem;
    }
  }

  .become_login {
    margin: auto;
    margin-top: 0.04rem;
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    flex-wrap: wrap;

    .type_login {
      width: 48.42%;
      padding-bottom: 0.06rem;
      background-color: #ffffff;
      border-radius: 0.08rem;

      .type_top {
        width: 100%;
        height: 0.08rem;
      }

      .card_login {
        .card_logo {
          width: 0.3rem;
          height: 0.3rem;
          margin: auto;
          margin-top: 0.1rem;

          img {
            width: 100%;
            height: 100%;
          }
        }

        .card_name {
          height: 0.28rem;
          font-size: 0.2rem;
          font-family: PingFang SC, PingFang SC;
          font-weight: 500;
          color: #5eb15c;
          text-align: center;
          line-height: 0.28rem;
        }
      }

      .multiple {
        height: 0.2rem;
        font-size: 0.14rem;
        font-family: PingFang SC, PingFang SC;
        font-weight: 400;
        color: #858c9f;
        line-height: 0.16rem;
        text-align: center;
        margin-top: 0.2rem;
      }

      .Unlock {
        width: 88.1%;
        height: 0.3rem;
        font-size: 0.14rem;
        font-family: PingFang SC, PingFang SC;
        font-weight: 500;
        color: #ffffff;
        line-height: 0.3rem;
        margin: auto;
        text-align: center;
        margin-top: 0.1rem;
        background-repeat: no-repeat;
        background-size: 100% 100%;
      }

      .task {
        padding-left: 0.06rem;
        padding-right: 0.06rem;
        margin-top: 0.2rem;

        .completion {
          display: flex;
          justify-content: left;

          .complete {
            width: 0.2rem;
            height: 0.2rem;

            img {
              width: 100%;
              height: 100%;
            }
          }

          .points {
            width: 0.04rem;
            height: 0.04rem;
            border-radius: 50%;
            margin-top: 0.07rem;
          }

          .task_describe {
            width: 1.46rem;
            text-align: justify;
            font-size: 0.14rem;
            font-family: PingFang SC, PingFang SC;
            font-weight: 400;
            color: #444444;
            line-height: 0.2rem;
            margin-left: 0.06rem;
          }
        }

        p {
          font-size: 0.14rem;
          font-family: PingFang SC, PingFang SC;
          font-weight: 400;
          color: #858c9f;
          line-height: 0.2rem;
          margin-left: 0.26rem;
        }
      }
    }

    .type_login:nth-child(1) {
      .type_top {
        background: #5eb15c;
        border-radius: 0.06rem 0.06rem 0rem 0rem;
      }
    }

    .type_login:nth-child(2) {
      .type_top {
        background: #ed931e;
        border-radius: 0.06rem 0.06rem 0rem 0rem;
      }
    }
  }
}

.new_box {
  width: 92.5%;
  margin: auto;
  background-color: #ffffff;
  border-radius: 0.06rem;
  margin-bottom: 0.14rem;

  .new_name {
    height: 0.18rem;
    font-size: 0.13rem;
    font-family: PingFang SC, PingFang SC;
    font-weight: 500;
    color: #59390f;
    line-height: 0.15rem;
    margin-left: 0.1rem;
    padding-top: 0.1rem;
  }

  .new_contant {
    padding-left: 0.1rem;
    padding-right: 0.1rem;
    font-size: 0.12rem;
    font-family: PingFang SC, PingFang SC;
    font-weight: 400;
    color: #858c9f;
    line-height: 0.2rem;
    margin-top: 0.06rem;

    .new_text {
      text-align: justify;
      padding-bottom: 0.1rem;
    }
  }
}

.modole {
  display: flex;
  margin-left: 0.14rem;
  padding-top: 0.14rem;

  .stick {
    margin-top: 0.06rem;
    width: 0.02rem;
    height: 0.1rem;
    background: #ff5f5b;
    border-radius: 0.06rem;
    opacity: 1;
  }

  .title {
    height: 0.22rem;
    font-size: 0.16rem;
    font-family: PingFang SC, PingFang SC;
    font-weight: 500;
    color: #59390f;
    line-height: 0.22rem;
    margin-left: 0.06rem;
  }

  .more_more {
    height: 0.2rem;
    font-size: 0.14rem;
    font-family: PingFang SC, PingFang SC;
    font-weight: 400;
    color: #ed931e;
    line-height: 0.19rem;
  }

  .more_img {
    width: 0.2rem;
    height: 0.2rem;

    img {
      width: 100%;
    }
  }
}

.characteristic {
  width: 100%;
  margin: auto;
  margin-top: 0.04rem;
  margin-left: 0.04rem;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  .box {
    width: 44%;
    margin: 0.1rem;
    margin-top: 0.12rem;
    position: relative;

    .characteristic_logo {
      width: 100%;
      position: relative;
      aspect-ratio: 77.42/100;

      img {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    .characteristic_name {
      position: absolute;
      width: 100%;
      top: 1.3rem;
      left: 0.1rem;
      margin: auto;
      font-size: 0.16rem;
      font-family: PingFang SC, PingFang SC;
      font-weight: 500;
    }

    .characteristic_describe {
      position: absolute;
      width: 92%;
      top: 1.58rem;
      left: 0.1rem;
      margin: auto;
      color: #858c9f;
      font-size: 0.14rem;
      font-family: PingFang SC, PingFang SC;
      font-weight: 500;
    }
  }

  .box:nth-child(1) {
    .characteristic_name {
      color: #6d60e6;
    }
  }

  .box:nth-child(2) {
    margin-left: 0.1rem;

    .characteristic_name {
      color: #0066ff;
    }
  }

  .box:nth-child(3) {
    .characteristic_name {
      color: #5eb15c;
    }
  }

  .box:nth-child(4) {
    margin-left: 0.1rem;

    .characteristic_name {
      color: #ed931e;
      text-align: center;
      left: 0;
    }

    .characteristic_describe {
      width: 100%;
      text-align: center;
      left: 0rem;
    }
  }
}

.become {
  width: 92.5%;
  margin: auto;
  margin-top: 0.04rem;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  .type {
    width: 100%;
    height: 1.56rem;
    margin-top: 0.12rem;
    position: relative;

    .beijing {
      width: 100%;
      height: 1.56rem;

      img {
        width: 100%;
        height: 100%;
      }
    }

    .card {
      padding: 0;
      display: flex;
      justify-content: center;
      position: absolute;
      z-index: 999;
      top: 0.27rem;
      left: 50%;
      width: 1.22rem;
      height: 0.34rem;
      background: rgba(237, 147, 30, 0.2);
      border-radius: 0.06rem;
      transform: translateX(-50%);

      .card_logo {
        width: 0.2rem;
        height: 0.2rem;
        margin-top: 0.07rem;

        img {
          width: 100%;
          height: 100%;
        }
      }

      .card_name {
        height: 0.34rem;
        line-height: 0.34rem;
        margin-left: 0.06rem;
      }
    }

    .multiple {
      width: 100%;
      text-align: center;
      font-size: 0.24rem;
      position: absolute;
      font-family: MiSans, MiSans;
      top: 0.73rem;
    }

    .describe {
      width: 100%;
      text-align: center;
      position: absolute;
      top: 1.1rem;
    }
  }

  .type:nth-child(1) {
    color: rgba(94, 177, 92, 1);

    .card {
      background-color: rgba(94, 177, 92, 0.2);
    }
  }

  .type:nth-child(2) {
    color: rgba(237, 147, 30, 1);

    .card {
      background-color: rgba(237, 147, 30, 0.2);
    }
  }
}

.copyright {
  width: 92.5%;
  height: 1rem;
  margin: auto;
  background: url(~@/assets/img/copyright.png) no-repeat;
  background-size: 100% 100%;

  .title {
    width: 100%;
    text-align: center;
    height: 0.22rem;
    font-size: 0.16rem;
    font-family: PingFang SC, PingFang SC;
    font-weight: 500;
    color: #ffffff;
    line-height: 0.19rem;
    padding-top: 0.12rem;
  }

  .introduce {
    width: 100%;
    text-align: center;
    height: 0.18rem;
    font-size: 0.13rem;
    font-family: PingFang SC, PingFang SC;
    font-weight: 400;
    color: rgba(255, 255, 255, 0.5);
    line-height: 0.15rem;
    margin-top: 0.04rem;
  }

  .details {
    width: 0.8rem;
    height: 0.24rem;
    background: #ffffff;
    border-radius: 0.38rem;
    opacity: 1;
    margin: auto;
    margin-top: 0.08rem;
    text-align: center;
    line-height: 0.24rem;
    font-size: 0.12rem;
    font-family: PingFang SC, PingFang SC;
    font-weight: 400;
    color: #222222;
  }
}
</style>
