<template>
  <div class="floor_text" style="background-color: #f6f6f8; font-size: 0.1rem">
    <span>2023 © All Rights Reserved.</span>
  </div>
</template>
<script lang="ts" setup></script>

<style lang="less" scoped>
:deep(.van-cell-group) {
  width: 92.5%;
  margin: auto;
  padding-top: 0.16rem;
  background-color: #f6f6f8;
  border: none !important;

  .van-cell {
    background-color: #f6f6f8;
    border-bottom: 0.01rem solid rgba(133, 140, 159, 0.2);
  }
}

.floor_text {
  width: auto;
  display: flex;
  flex-direction: column;
  padding-top: 0.2rem;
  text-align: center;
  font-size: 0.12rem;
  color: #858c9f;
  line-height: 0.24rem;

  a {
    all: unset;
  }
}
</style>
